



































































































































































import { participantsService } from "@/services";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  RequestStatus,
  HelpTypes,
  YesNo,
  Duration,
  Frequency,
} from "@/helpers";

@Component
export default class CareRecipientItem extends Vue {
  private caregivers: [] = [];
  private caregiverInfo: any = undefined;
  private availableList: [] = [];
  private participants: [] = [];
  private caregiverId = "";

  @Prop() private careRecipientItem!: {
    _id: string;
    helptype: string;
    caregiver: any;
    status: string;
    caregiverAlt: object[];
    person: any;
  };

  constructor() {
    super();
    this.findCaregivers();
    this.getAllPArticipants();
  }

  data() {
    return {
      requestStatus: RequestStatus,
      helptypes: HelpTypes,
      yesNo: YesNo,
      duration: Duration,
      frequency: Frequency,
      caregivers: this.caregivers,
      caregiverId: this.caregiverId,
      caregiverAltIds: [],
      item: this.careRecipientItem,
      participants: this.participants,
    };
  }

  add() {
    try {
      const exists =
        this.careRecipientItem.caregiverAlt.filter(
          (p: any) => p._id == this.caregiverId
        ).length > 0;
      if (!exists) {
        const [item] = this.availableList.filter(
          (p: any) => p._id === this.caregiverId
        );
        if (item) {
          this.careRecipientItem.caregiverAlt.push(item);
          [this.careRecipientItem.caregiver] =
            this.careRecipientItem.caregiverAlt;

          console.log(this.careRecipientItem.caregiverAlt);

          this.showCaregiver();
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  remove(id: string) {
    const [item] = this.careRecipientItem.caregiverAlt.filter(
      (p: any) => p._id === id
    );
    if (item) {
      this.careRecipientItem.caregiverAlt =
        this.careRecipientItem.caregiverAlt.filter((p: any) => p._id !== id);
      [this.careRecipientItem.caregiver] = this.careRecipientItem.caregiverAlt;
    }
    this.showCaregiver();
  }

  startsWithCapital(word: string): boolean {
    return word.charAt(0) === word.charAt(0).toUpperCase();
  }

  chopkUntilCapital(word: string): string {
    let str = word;
    while (!this.startsWithCapital(str)) {
      str = str.substring(1);
      if (str.length === 0) break;
    }
    if (str.length === 0) return word;

    return str;
  }

  getAllPArticipants() {
    participantsService.getAll().then((participants) => {
      this.participants = participants
        .sort((p: any, q: any) => {
          const pLastName = this.chopkUntilCapital(p.lastName);
          const qLastName = this.chopkUntilCapital(q.lastName);

          if (pLastName > qLastName) return 1;
          if (pLastName < qLastName) return -1;

          return 0;
        })
        .map((p: any) => ({
          value: p._id,
          text: `${p.displayName} - ${p.mobileNumber} - ${p.email}`,
        }));
    });
  }

  findCaregivers() {
    if (this.careRecipientItem.helptype) {
      participantsService
        .getAvailableCaregiverItems(this.careRecipientItem.helptype)
        .then((availableList) => {
          this.availableList = availableList;
          this.caregivers = availableList
            .filter((p: any) => p._id !== this.careRecipientItem.person._id)
            .sort((p: any, q: any) => {
              if (p.lastName > q.lastName) return 1;
              if (p.lastName < q.lastName) return -1;

              return 0;
            })
            .map((p: any) => ({
              value: p._id,
              text: `${p.displayName} - ${p.zipcode} - ${p.mobileNumber} - (${p.count})`,
            }));
          this.showCaregiver();
          if (!this.careRecipientItem.caregiverAlt) {
            this.careRecipientItem.caregiverAlt = [];
          }
        });
    }
  }

  onHelptypeChange() {
    this.careRecipientItem.caregiverAlt = [];
    this.careRecipientItem.caregiver = undefined;
    this.findCaregivers();
  }
  resetCaregiverIfNeeded() {
    if (this.careRecipientItem.status == "1") {
      this.showCaregiver();
    }
  }

  showCaregiver() {
    if (
      this.careRecipientItem.caregiverAlt &&
      this.careRecipientItem.caregiverAlt.length > 0 &&
      this.careRecipientItem.status === "1"
    ) {
      this.careRecipientItem.status = "2";
    }
    console.log(this.careRecipientItem.caregiverAlt);
    if (
      this.careRecipientItem.caregiverAlt &&
      this.careRecipientItem.caregiverAlt.length === 0 &&
      this.careRecipientItem.status !== "1"
    ) {
      this.careRecipientItem.status = "1";
    }
    this.$forceUpdate();
  }
}
